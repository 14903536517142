.navigation {
    height: 60px;;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    display: flex;
    padding-top: 8px;
}


.logo {
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
    place-self: center;
    margin: auto;
    display: flex;
}

.logo h2 {
    color: #fff;
    font-weight: bold;
    font-family: serif;
    font-size: 1.2rem;
}

.arrow__back {
    float: left;
    position: absolute;
    margin-left: 20px;
    margin-top: 19px;
    height: 60px;
    font-size: 30px;
    z-index: 1000;
    color: #fff;
}

.title {
    margin: 10px 20px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.title h1 {
    text-align: center;
}

.content__container {
    margin: 40px 20px;
}

.content__container p {
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
}

.content__container h3 {
    margin-top: 10px;
}